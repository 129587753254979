import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"faq"} />
      <Helmet>
        <title>Wera Auto Service'da SSS | SSS'ler</title>
        <meta
          name={"description"}
          content={"Sizin Sorularınız, Bizim Cevaplarımız"}
        />
        <meta
          property={"og:title"}
          content={"Wera Auto Service'da SSS | SSS'ler"}
        />
        <meta
          property={"og:description"}
          content={"Sizin Sorularınız, Bizim Cevaplarımız"}
        />
        <meta
          property={"og:image"}
          content={"https://xovilo.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://xovilo.com/img/2547477.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://xovilo.com/img/2547477.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://xovilo.com/img/2547477.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://xovilo.com/img/2547477.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://xovilo.com/img/2547477.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://xovilo.com/img/2547477.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="100px 0 100px 0"
        background="#ffffff"
        quarkly-title="FAQ-11"
      >
        <Text
          margin="0px 0px 30px 0px"
          font="normal 900 52px/1.2 --fontFamily-sans"
          color="#0e1940"
          text-align="center"
          lg-margin="0px 0px 50px 0px"
          sm-font="normal 900 36px/1.2 --fontFamily-sans"
        >
          Sorgularınızda Gezinme
        </Text>
        <Text margin="0px 0px 40px 0px" font="--lead" color="--darkL2">
          Wera Auto Service'ın SSS bölümüne hoş geldiniz, burada açıklık ve
          kolaylık bir araya geliyor. Hizmetlerimiz, politikalarımız ve daha
          fazlası hakkında sorularınız olabileceğini biliyoruz. Bu sayfa,
          aldığımız en yaygın sorulardan bazılarına hızlı yanıtlar sağlamak için
          tasarlanmıştır. Sorunuz burada yer almıyorsa, kişiselleştirilmiş
          yardım için bize ulaşmaktan çekinmeyin.
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="16px 24px"
          lg-grid-template-columns="1fr"
        >
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Ne tür araçlara hizmet veriyorsunuz?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Wera Auto Service'da çeşitli marka ve modellerde otomobiller,
                kamyonlar ve SUV'lar dahil olmak üzere çok çeşitli araçlara
                hizmet veriyoruz. Ekibimiz, özel araç ihtiyaçlarınızı
                karşılayacak uzmanlık ve araçlarla donatılmıştır.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Nasıl servis randevusu alabilirim?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Randevu almak kolaydır ve bizi arayarak veya e-posta göndererek
                yapılabilir. Sizin için en uygun zamanı bulacağız.
              </Text>
            </Box>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Hizmetleriniz için garanti sunuyor musunuz?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Evet, tüm hizmetler ve parçalar için kapsamlı bir garanti
                sunarak her ziyarette gönül rahatlığı ve memnuniyet sağlıyoruz.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Aracım bozulursa ne yapmalıyım?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Acil yardıma ihtiyacınız varsa lütfen hemen bizi arayın. Acil
                durum hizmetleri sunuyoruz ve gerekirse çekici hizmetleri de
                dahil olmak üzere atmanız gereken adımlar konusunda size
                rehberlik edebiliriz.
              </Text>
            </Box>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Aracıma ne sıklıkla bakım yaptırmalıyım?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Bakım sıklığı aracınızın markasına, modeline ve sürüş
                koşullarına bağlıdır. Kişiselleştirilmiş bir bakım programı için
                kullanım kılavuzunuza bakmanızı ve teknisyenlerimizle
                görüşmenizi öneririz.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Aracımın bakımı yapılırken Wera Auto Service'da bekleyebilir
                miyim?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Kesinlikle bekleyebilirsiniz! Rahatlamanız için konforlu bir
                bekleme alanımız var. Biz aracınızla ilgilenirken siz de
                olanaklarımızın keyfini çıkarın.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://xovilo.com/img/3.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
                Daha Fazla Yardım
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Sorunuz burada ele alınmadıysa, lütfen iletişime geçmekten
                çekinmeyin. Sorularınızı yanıtlamak ve ihtiyacınız olan
                bilgileri sağlamak için her zaman hazırız. Daha detaylı bilgi
                için bizi arayabilir veya Wera Auto Service'ı ziyaret
                edebilirsiniz.
              </Text>
            </Box>
          </Box>
        </LinkBox>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
